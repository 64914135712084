@import '../../variables.scss';

$left-width: 55vw;

.homepage {
    height: 100vh;

    .slant-background-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: $left-width;
        height: 100vh;
        filter: drop-shadow(8px 0px 8px rgba(black, .4));

        .slant-background {
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to right, var(--color-primary), var(--color-primary-dark));
            clip-path: polygon(0rem 0rem, 85% 0rem, 100% 100%, 0rem 100%);
            animation: slideRight .5s ease-out;
        }
    }

    .content {
        display: flex;
        height: calc(100vh - 6rem);
    }

    .text-container {
        text-align: center;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: $left-width;
        animation: slideRight .5s ease-out;

        & > * {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 0 15%;
            box-sizing: border-box;
        }

        .text {
            font-size: 6rem;
            letter-spacing: .5rem;
            width: 95%;
            margin: 0 auto 6rem auto;
        }

        .subtext {
            font-size: 3rem;
            align-self: center;
        }
    }

    .graphic-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45vw;
        padding: 2rem;
        box-sizing: border-box;
        overflow: hidden;
    }

    .graphic {
        width: 45rem;
        z-index: -20;
    }
}

@media screen and (max-width: 900px) {
    .homepage {

        .text-container {
            .text {
                font-size: 3.8rem;
            }
    
            .subtext {
                font-size: 2rem;
            }
        }

    .graphic {
            width: 40rem;
        }
    }
}

@media screen and (max-width: 600px) {
    .homepage {

        .slant-background-container {
            width: 100vw;
            filter: drop-shadow(0px -8px 8px rgba(black, .4));

            .slant-background {
                background-image: linear-gradient(to top, var(--color-primary), var(--color-primary-dark));
                clip-path: polygon(0 6rem, 100% calc(10% + 6rem), 100% 100%, 0 100%);
                animation: slideUp .5s ease-out;
            }
        }

        .content {
            flex-direction: column;
        }

        .text-container {
            width: auto;
            flex: 1;
            animation: slideUp .5s ease-out;

            .text {
                padding-top: 6rem;
            }
        }

        .graphic-container {
            width: auto;
            flex: 1;
        }

        .graphic {
            z-index: 10;
            animation: slideUp .5s ease-out;
        }
    }
}

@media screen and (max-width: 480px) {
    .homepage {

        .text-container {
            .text {
                font-size: 4rem;
            }
    
            .subtext {
                font-size: 1.8rem;
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .homepage {

        .text-container {
            .text {
                font-size: 2.4rem;
            }
    
            .subtext {
                font-size: 1.6rem;
            }
        }
    }
}