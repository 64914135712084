@import '../../variables.scss';

.menu {
    position: absolute;
    top: 6rem;
    right: 0rem;
    border: 2px solid var(--color-primary);
    border-radius: 0 0 0 .5rem;
    border-top: 0;
    border-right: 0;
    display: flex;
    flex-direction: column;
    width: 24rem;
    background-color: white;
    z-index: 10;

    &-item {
        color: var(--color-primary);
        padding: 2rem 0;
        text-decoration: none;
        margin: 0;
        text-align: center;
        cursor: pointer;

        &:hover {
            background-color: var(--color-primary-superfaded);
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--color-primary-superfaded);
        }
    }

    &-logout-button:hover {
        background-color: var(--color-primary-faded);
        color: white;
    }
}