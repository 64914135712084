@import '../../variables.scss';

.add-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    height: 6rem;
    border: none;
    border-radius: .5rem;
    padding: 0;
    margin: 1rem 0;
    background-color: var(--color-primary-faded);
    box-shadow: 1rem 1rem 2rem rgba(black, .2);
    cursor: pointer;
    transition: all .3s;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 1rem 2rem 3rem rgba(black, .2);
    }
    
    &:active {
        transform: translateY(2px);
        box-shadow: 1rem 1rem 2rem rgba(black, .2);
    }

    .plus-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 10%;
    
        .plus-icon {
            color: white;
        }
    }
    
    &-text {
        color: white;
        font-family: Noir;
        font-size: 2.4rem;
        margin-left: 2rem;
    }
}

@media screen and (max-width: 900px) {
    .add-item {
        width: 80%;

        &-text {
            font-size: 2rem;
        }
    }
}

@media screen and (max-width: 600px) {
    .add-item {
        width: 90%;

        &-text {
            font-size: 1.8rem;
            margin-left: 1rem;
        }
    }
}

@media screen and (max-width: 480px) {
    .add-item {
        height: 5rem;

        &-text {
            font-size: 1.6rem;
            margin-left: .5rem;
        }
    }
}