@import '../../variables.scss';

.user-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 4rem 0;

        .list {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    }

    .title {
        font-size: 3.6rem;
        text-align: center;
    }

    .exit-button {
        align-self: flex-end;
        margin-right: 15%;
        color: white;
        background-color: var(--color-primary);
        font-size: 2rem;
        border: none;
        border-radius: 2rem;
        padding: 1rem;
        width: 12rem;
        outline: none;
        transition: all .2s;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 1rem 1rem rgba(black, .2);
        }
        
        &:active {
            transform: translateY(2px);
            box-shadow: none;

        }
    }
}

@media screen and (max-width: 900px) {
    .user-page {

        .title {
            font-size: 3.2rem;
        }

        .exit-button {
            margin-right: 10%;
        }
    }
}

@media screen and (max-width: 600px) {
    .user-page {

        .title {
            font-size: 3rem;
        }

        .exit-button {
            align-self: center;
            margin-right: 0;
            font-size: 1.8rem;
            padding: .5rem;
        }
    }
}

@media screen and (max-width: 480px) {
    .user-page {
        
        .title {
            font-size: 2.4rem;
        }
    }
}