@import '../../variables.scss';

.todo {
    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    width: 70%;
    border-radius: .5rem;
    margin: 1rem 0;
    box-shadow: 1rem 1rem 2rem rgba(black, .2);

    &-edit-form {
        width: 85%;

        &-textfield {
            color: black;
            font-family: 'Noir';
            font-size: 2.4rem;
            width: calc(100% - 1.5rem);
            margin-left: 1.5rem;
            padding: 0;
            border: none;
            line-height: 4rem;

            &.text-completed {
                text-decoration: line-through;
                color: rgba(black, .4);
            }

            &:focus {
                outline: none;
            }
        }
    }

    &-delete-form {
        width: 5%;
        padding: 0 1rem;
        display: flex;
        justify-content: center;

        .todo-icon-button {
            justify-self: flex-end;
            padding: .5rem;
            visibility: hidden;

            &:hover {
                background-color: var(--color-primary-dark);
                transform: rotate(90deg);
                transition: all .3s;

                & .todo-clear-icon {
                    color: white;
                    transition: all .3s;
                }
            }
    
            .todo-clear-icon {
                color: var(--color-primary-dark);
                border-radius: 2rem;
            }
        }
    }

    &:hover &-icon-button {
        visibility: visible;
    }
}

@media screen and (max-width: 900px) {
    .todo {
        width: 80%;

        .todo-edit-form-textfield {
            font-size: 2rem;
        }
    }
}

@media screen and (max-width: 600px) {
    .todo {
        width: 90%;

        .todo-edit-form-textfield {
            font-size: 1.8rem;
            margin-left: 1rem;
        }
    }
}

@media screen and (max-width: 480px) {
    .todo {
        height: 5rem;
        margin: .75rem 0;

        .todo-edit-form-textfield {
            font-size: 1.6rem;
        }

        .todo-delete-form {
            display: none;
        }
    }
}