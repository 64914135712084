@import '../../variables.scss';

.toggle-button-container {
    width: 10%;
    height: 6rem;
    border-radius: .5rem 0 0 .5rem;
    transition: all .15s;
    
    &:hover {
        filter: drop-shadow(3px 0px 3px rgba(black, .3));
    }

    &:active {
        filter: drop-shadow(2px 0px 2px rgba(black, .3));
    }
}

.toggle-button {
    width: 100%;
    height: 100%;
    border-radius: .5rem 0 0 .5rem;
    clip-path: polygon(-.5px -.5px, 85% -.5px, 100% calc(100% + .5px), -.5px calc(100% + .5px));

    &:hover {
        transform: scale(1.05);
        transition: transform .15s ease-in;
    }

    &:active {
        transform: scale(1);
        transition: transform .15s ease-in;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        border: none;
        border-radius: .5rem 0 0 .5rem;
        background-color: var(--color-primary);
        clip-path: polygon(-.5px -.5px, 85% -.5px, 100% calc(100% + .5px), -.5px calc(100% + .5px));
        outline: none;
        cursor: pointer;
    
        .check-icon {
            color: white;
            padding-right: 7.5%;
        }
    }
    
    .selected {
        background-color: var(--color-primary-dark);
    }
    
    .check-icon.selected {
        color: #c8c8c8;
    }
}

@media screen and (max-width: 480px) {
    .toggle-button-container {
        height: 5rem;
    }
}