.sign-in {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 6rem);
    min-height: 60rem;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        width: 80%;
        background-color: white;
        box-shadow: 1rem 1rem 2rem rgba(black, .2);
        border-radius: .5rem;

        .shadow-container {
            height: 100%;
            width: 50%;
            filter: drop-shadow(8px 0px 16px rgba(black, .4));
        }

        .sign-in-left {
            height: 100%;
            background-image: linear-gradient(to right, var(--color-primary), var(--color-primary-dark));
            clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
            border-radius: .5rem 0 0 .5rem;
            animation: slideRight .5s ease-out;

            .graphic-container {
                position: relative;
                width: 85%;
                height: 100%;
                padding: 2rem;
                padding-right: 0;
                box-sizing: border-box;
            }

            .bench-graphic {
                position: absolute;
                bottom: 10%;
                width: calc(100% - 2rem);
            }
        }
    
        .sign-in-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
            width: 50%;
            padding: 2rem;
            padding-left: 0;
            box-sizing: border-box;

            .sign-in-title {
                text-align: center;
                color: var(--color-primary);
                font-size: 4rem;
                margin: 4rem 0 8rem 0;
            }
    
            .sign-in-form {
                display: flex;
                flex-direction: column;
                align-self: center;
                width: 80%;
        
                 & input {
                     border:none;
                     background-color: var(--color-primary-superfaded);
                     color: var(--color-primary-dark);
                     border-radius: .5rem;
                     padding: 1rem;
                     margin-bottom: 1.6rem;
                     font-size: 1.6rem;

                     &::placeholder {
                         color: var(--color-primary-faded);
                     }
        
                     &:focus {
                         outline: none;
                     }
                 }
        
                 & button {
                     color: white;
                     background-color: var(--color-primary);
                     font-size: 2rem;
                     border: none;
                     border-radius: 2rem;
                     padding: 1rem;
                     outline: none;
                     transition: all .2s;
                     margin: .5rem 0;
        
                     &:hover {
                         transform: translateY(-2px);
                         box-shadow: 0 1rem 1rem rgba(black, .2);
                     }
                     
                     &:active {
                         transform: translateY(2px);
                         box-shadow: none;
                     }
                 }
            }

            .sign-in-error-message {
                margin-top: 2rem;
                font-size: 2rem;
                background-color: rgba(red, .2);
                color: rgba(#a13838, .8);
                padding: 1rem;
                border-radius: .5rem;
                width: 80%;
                align-self: center;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .sign-in {

        .content {

            .sign-in-right {

                .sign-in-form {
                    width: 85%;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .sign-in {

        .content {
            flex-direction: column-reverse;
            height: 90%;
            width: 85%;

            .shadow-container {
                width: 100%;
                filter: drop-shadow(0px -8px 16px rgba(black, .4));
            }

            .sign-in-right {
                width: 100%;

                .sign-in-title {
                    font-size: 3.6rem;
                    margin: 3rem 0 6rem 0;
                }

                .sign-in-form {

                    & button {
                        font-size: 1.6rem;
                    }
                }

                .sign-in-error-message {
                    margin-top: 1rem;
                    font-size: 1.6rem;
                }
            }

            .sign-in-left {
                display: flex;
                align-items: flex-end;
                background-image: linear-gradient(to top, var(--color-primary), var(--color-primary-dark));
                clip-path: polygon(calc(-.5px) 0, calc(100% + .5px) 15%, calc(100% + .5px) 100%, -.5px 100%);
                border-radius: 0 0 .5rem .5rem;
                animation: slideUp .5s ease-out;

                .graphic-container {
                    width: 100%;
                    height: 85%;
                    padding: 0 1rem;
                }

                .bench-graphic {
                    bottom: 5%;
                }
            }

            .sign-in-right {
                padding: 0 1rem;
            }
        }
    }
}