body {
    background-color: #f3f3f3;
    font-family: 'Noir';
}

@font-face {
    font-family: 'Noir';
    src: url('./assets/Noir_regular.otf');
    font-display: swap;
}

@keyframes slideRight {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translate(0);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(50vh);
    }

    to {
        transform: translatY(0);
    }
}