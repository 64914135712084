@import '../../variables.scss';

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 6rem);
    
    .ripple {
        display: inline-block;
        position: relative;
        width: 30rem;
        height: 30rem;
      }

      .ripple div {
        position: absolute;
        border: .4rem solid var(--color-primary);
        opacity: 0;
        border-radius: 50%;
        animation: ripple 1.8s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }

      .ripple div:nth-child(2) {
        animation-delay: .6s;
      }

      .ripple div:nth-child(3) {
        animation-delay: 1.2s;
      }
}

@keyframes ripple {
    0% {
        height: 0;
        width: 0;
        top: 50%;
        left: 50%;
        opacity: 1;
    }

    100% {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
    }
}
  