@import '../../variables.scss';

.nav {
    display: flex;
    justify-content: flex-end;
    margin: auto;
    align-items: center;
    height: 2rem;
    font-size: 2rem;
    background-color: var(--color-primary);
    padding: 2rem;
    box-shadow: 0 0rem 2rem 1rem rgba(black, .2);

    &-transparent {
        background-color: transparent;
        box-shadow: none;
    }

    &-buttons {
        display: flex;
        align-items: center;

        .nav-username {
            color: white;
            margin-right: 6rem;
            white-space: nowrap;
        }

        .nav-item {
            text-decoration: none;
            text-align: center;
            color: white;
            padding: .5rem 2rem;
            margin-left: 1rem;
            margin-right: 1rem;
            cursor: pointer;

            // for shimmer effect
            background-size: 200% 100%;
            color: transparent;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;

            background-color: white;
            background-image: linear-gradient(
                -80deg,
                transparent 0,
                transparent 5%,
                var(--color-primary-faded) 5%,
                var(--color-primary-faded) 10%,
                transparent 10%,
                transparent 100%,
            );

            &.nav-transparent {
                background-color: var(--color-primary);
                background-image: linear-gradient(
                    -80deg,
                    transparent 0,
                    transparent 5%,
                    rgba(#fff, .5) 5%,
                    rgba(#fff, .5) 10%,
                    transparent 10%,
                    transparent 100%,
                );
            }

            &:hover {
                animation: shimmer .6s;
            }
        }

        .menu-icon {
            height: 2rem;
            padding: 2rem;
            overflow: visible;
            cursor: pointer;

            &:hover #top {
                animation: moveLeft .2s ease-out;
            }

            &:hover #middle {
                animation: moveLeft .2s .1s ease-out;
            }

            &:hover #bottom {
                animation: moveLeft .2s .2s ease-out;
            }
        }
    }
}

// For CSS Transition Group animation
.menu-slide-in {

    &-enter {
        transform: translateX(100%);
    }

    &-enter.menu-slide-in-enter-active {
        transform: translateX(0rem);
        transition: all .5s ease-in-out;
    }

    &-exit {
        transform: translateX(0rem);
    }

    &-exit.menu-slide-in-exit-active {
        transform: translateX(100%);
        transition: all .5s ease-in-out;
    }
}

@media screen and (max-width: 480px) {
    .nav {
        
        .nav-buttons {
            width: 100%;
            justify-content: center;
            justify-content: space-between !important;
    
            .nav-username {
                margin-right: 0;
                padding-left: 2rem;
            }
    
            .nav-item {
                margin-left: 0;
                margin-right: 0;
    
                .nav-item:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@keyframes moveLeft {
    0% {
        transform: translateX(0rem);
    }

    50% {
        transform: translateX(-3rem);
    }

    100% {
        transform: translateX(0rem);
    }
}

@keyframes shimmer {
    0% {
        background-position: 0, 0;
    }

    100% {
        background-position: -120%, 0;

    }
}