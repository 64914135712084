@import '../../variables.scss';

.reward {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 70%;
    font-size: 2rem;
    background-color: white;
    color: white;
    border-radius: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 1rem 1rem 2rem rgba(black, .2);
    transition: transform .3s;

    &.locked {
        transform: scale(90%);

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(black, .3);
            z-index: 100;
            border-radius: .5rem;
            pointer-events: none;
        }
    }

    &-form {
        align-self: center;
        width: 100%;
        margin-left: 2rem;

        &-textfield {
            color: var(--color-primary);
            background-color: transparent;
            font-family: 'Noir';
            font-size: 2.4rem;
            width: 90%;
            border: none;
            border-bottom: 1px solid var(--color-primary);
            line-height: 4rem;
    
            &:focus {
                outline: none;
            }
        }
    }

    &-right-side {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--color-primary);
        padding-left: 8%;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% + .5px), 15% calc(100% + .5px));
        border-radius: 0 .5rem .5rem 0;

        .reward-to-complete {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin: auto;
    
            .requirements-title {
                text-align: center;
            }

            .requirements-list {
                width: 100%;
            }
        }

        .reward-buttons {
            display: flex;
            justify-content: space-between;
            height: 6rem;
            padding: 1rem 0 1rem 1rem;

            .requirement-add-button {
                align-self: flex-start;
                max-width: 7rem;
                padding: 0;
                transition: all .3s;

                &:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 1rem 1rem rgba(black, .2);
                }
                
                &:active {
                    transform: translateY(2px);
                    box-shadow: none;
                }
        
                .requirement-add-icon {
                    background-color: var(--color-primary-dark);
                    color: white;
                    border-radius: 2rem;
                    padding: 1rem;
                }
            }

            .reward-delete-form {
                align-self: flex-end;
                margin-bottom: -1rem;

                &-button {
                    padding: .5rem;
                    margin: 1.2rem;
                    visibility: hidden;

                    &:hover {
                        background-color: var(--color-primary-dark);
                        transform: rotate(90deg);
                        transition: all .3s;

                        & .reward-clear-icon {
                            color: white;
                            transition: all .3s;
                        }
                    }
                }

                .reward-clear-icon {
                    color: var(--color-primary-dark);
                    border-radius: 2rem;
                }
            }
        }
    }

    &:hover &-delete-form-button {
        visibility: visible;
    }
}

@media screen and (max-width: 900px) {
    .reward {
        width: 80%;

        .reward-form-textfield {
            font-size: 2rem;
        }
    }
}

@media screen and (max-width: 600px) {
    .reward {
        width: 90%;

        .reward-form-textfield {
            font-size: 1.8rem;
        }
    }
}

@media screen and (max-width: 480px) {
    .reward {
        flex-direction: column;
        padding: 0;

        .reward-form {
            display: flex;
            justify-content: center;
            margin: 0;
            width: 100%;

            .reward-form-textfield {
                font-size: 1.6rem;
                margin-top: 1rem;
                text-align: center;
                width: 90%;
                padding: 0;
                border: none;
            }
        }

        .reward-right-side {
            clip-path: polygon(0 4rem, 100% 0, 100% calc(100% + .5px), 0 calc(100% + .5px));
            width: 100%;
            align-items: center;
            padding-left: 0;
            border-radius: 0 0 .5rem .5rem;

            .reward-to-complete {
                display: flex;
                align-items: center;
                padding-left: 0;

                .requirements-title {
                    font-size: 2rem;
                    padding-top: 3rem;
                }
            }

            .reward-buttons {
                width: 90%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 1rem;

                .reward-delete-form-button {
                    margin: 0;
                }
            }
        } 
    }
}