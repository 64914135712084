@import '../../variables.scss';

.requirement {
    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    border-radius: 2rem;
    width: 100%;
    margin: 1rem 0rem;

    &-check-container {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1rem;

        .requirement-check-placeholder {
            // width of CheckRounded (large) from material-ui
            width: 21.825px;
            height: 100%;
        }
    }

    &-form {
        display: flex;
        justify-content: space-between;
        width: 95%;
        align-items: center;
        padding-right: 1rem;

        &-text {
            margin: 0;
            padding: 1rem 0;
            overflow-wrap: break-word;
            word-break: break-word;
        }

        .requirement-delete-button {
            padding: .5rem;
            margin-left: 1rem;
            visibility: hidden;

            &:hover {
                background-color: var(--color-primary-dark);
                transform: rotate(90deg);
                transition: all .3s;

                & .requirement-delete-icon {
                    color: white;
                    transition: all .3s;
                }
            }

            .requirement-delete-icon {
                color: var(--color-primary-dark);
                border-radius: 2rem;
            }
        }
    }

    &:hover &-delete-button {
        visibility: visible;
    }
}

@media screen and (max-width: 900px) {
    .requirement {
    
        .requirement-form-text {
            font-size: 1.8rem;
    
        }
    }
}

@media screen and (max-width: 600px) {
    .requirement {

        .requirement-form-text {
            font-size: 1.6rem;
        }
    }
}

@media screen and (max-width: 480px) {
    .requirement {

        .requirement-form-text {
            font-size: 1.4rem;
        }
    }
}